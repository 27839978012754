import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import logo from '../Assets/logo.png'; // Make sure the path is correct

const Navbar = () => {
    return (
        <AppBar position="static" style={{ backgroundColor: '#FFFFFF' }}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <img src={logo} alt="Mundo AI" style={{ height: '130px', width: '140px' }} />
                </Box>
                <Button
                    href="mailto:contact@mundoai.world"
                    sx={{
                        backgroundColor: '#000000',
                        color: '#FFFFFF',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        textTransform: 'none',
                        borderRadius: '100px',
                        padding: '10px 20px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        '&:hover': {
                            boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.4)', // Increased shadow on hover
                            backgroundColor: '#000000', // Maintain background color on hover
                        },
                    }}
                >
                    Request a demo
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;

