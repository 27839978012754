import React from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';

const Hero = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 170px)', // Decreased height to reduce space between Navbar and Hero
                backgroundColor: '#FFFFFF',
                padding: '0 90px', // Increased padding on the left to create more white space
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                    <Typography
                        variant="h3"
                        style={{
                            color: '#000000',
                            fontFamily: 'Montserrat',
                            fontWeight: 700,
                            marginBottom: '20px',
                            lineHeight: 1.2, // Adjust line height for better spacing
                        }}
                    >
                        AI for everyone,<br />in every language
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{
                            color: '#000000',
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            marginBottom: '40px',
                            lineHeight: 1.5, // Adjust line height for better spacing
                        }}
                    >
                        World’s largest multilingual dataset library.<br />Train your AI to speak like a native speaker.
                    </Typography>
                    <Button
                        variant="contained"
                        href="mailto:contact@mundoai.world"
                        sx={{
                            backgroundColor: '#000000',
                            color: '#FFFFFF',
                            fontFamily: 'Montserrat',
                            fontSize: '1.2rem',
                            fontWeight: 600,
                            textTransform: 'none',
                            borderRadius: '100px',
                            padding: '15px 30px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.4)', // Increased shadow on hover
                                backgroundColor: '#000000', // Maintain background color on hover
                            },
                        }}
                    >
                        Request a demo
                    </Button>
                </Grid>
                <Grid item xs={0} md={6} lg={7} />
            </Grid>
        </Box>
    );
};

export default Hero;
