import React from 'react';
import Navbar from './Components/NavBar';
import Hero from './Components/Hero';
import Footer from './Components/Footer';

function App() {
  return (
    <div style={{ backgroundColor: '#FFFFFF', color: '#000000' }}>
      <Navbar />
      <Hero />
      <Footer />
    </div>
  );
}

export default App;
