import React from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px',
                backgroundColor: '#000000',
                color: '#FFFFFF',
                fontFamily: 'Montserrat',
            }}
        >
            <Typography variant="body2" >
                © 2024 Mundo AI
            </Typography>
            <Box>
                <Paper sx={{ backgroundColor: '#2D2D2D', borderRadius: '100px' }}>
                    <IconButton href="https://www.linkedin.com/company/mundo-ai" target="_blank" rel="noopener noreferrer" sx={{ color: '#FFFFFF' }}>
                        <LinkedInIcon />
                    </IconButton>
                </Paper>
            </Box>
        </Box>
    );
};

export default Footer;
