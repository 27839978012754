import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/montserrat/400.css'; // Regular 400
import '@fontsource/montserrat/600.css'; // SemiBold 600
import '@fontsource/montserrat/700.css'; // Bold 700
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
